import React from "react";
import { H3_TESTIMONIAL_IMG } from "../../lib/assets";
import {
  SlickSlider,
  SlickSliderArrowNext,
  SlickSliderArrowPrev,
} from "../SlickSlider/SlickSlider";
import cn from 'classnames'

export const TestimonialAreaThree = ({className}) => {
  const slick_settings_brands = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    fade: true,
    arrows: true,
    prevArrow: (
      <SlickSliderArrowPrev classNameWrapper="testimonial-nav-three testimonial-nav-three-prev" />
    ),
    nextArrow: (
      <SlickSliderArrowNext classNameWrapper="testimonial-nav-three testimonial-nav-three-next" />
    ),
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={cn("testimonial-area-three", className)}>
      <div className="container">
        <div className="row g-0 align-items-end">
          <div className="col-37">
            <div className="testimonial-img-three">
              <img src={H3_TESTIMONIAL_IMG} alt="" />
            </div>
          </div>

          <div className="col-63">
            <div className="testimonial-item-wrap-three">
              <div className="testimonial-active-three">
                <SlickSlider settings={slick_settings_brands}>
                  <div className="testimonial-item-three">
                    <div className="testimonial-content-three">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “ Fast to respond, follows through with information promised, and this team is very knowledgeable. ”,
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Ms.Bronte Clifford</h2>
                        <span>School of Phlebotomy</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-three">
                    <div className="testimonial-content-three">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “ I am very pleased with the re-design of my website www.rkvalve.com by OrbitSolve. However, OrbitSolve helped me identify a design that is personalized, warm and easy to browse. 
                        I would highly recommend Premier Web Design Solutions to any business/individual for their Internet marketing needs.. ”
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Mr.Babu Suresh</h2>
                        <span>RK Industries</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-three">
                    <div className="testimonial-content-three">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “ It has been my pleasure to work with OrbitSolve for years. Besides very quick and cost effective web design and storage, also very trustable, always on time for publishing materials online. ”
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Stella Gu</h2>
                        <span>Liangyi Youth Leadership Academy</span>
                      </div>
                    </div>
                  </div>                  
                </SlickSlider>
              </div>

              {/* <div className="testimonial-nav-three"></div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
