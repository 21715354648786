import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-investment",
    title: "Finance Investment",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Advisory",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "Requirement & Data Analysis",
    subtitle: "We learn your requirement and anlyse your data from a product owner perspective, which will make us creative to provide you a perfect solution.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "UX/UI Design",
    subtitle: "Proper design of User Experience (UX) and User Interface (UI) makes applications more successful, and better received by users.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Website Development",
    subtitle: "A website with interactive User Interface is mandatory for any business.  We help businesses to deploy their websites quickly and provide high availability and low latency.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "App Development",
    subtitle: "With the growing number of people accessing the Internet via smartphones and tablets, mobile app development has the unique ability to access a large number of potential consumers.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-life-insurance",
    title: "More coverage",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Less hassle",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    title: "Faster benefits",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Quality Services",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Innovation Ideas",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Business Growth",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
];
